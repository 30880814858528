import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import { CircularProgress } from '@mui/material/';


const PrivateRoute = ({ element }) => {
    const { user, loading } = useContext(AuthContext);

    if (loading) {
        return <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
        ><CircularProgress size={50} color="inherit" /></div>; // Show spinner while checking auth status
    }

    return user ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
