import React, { useState } from "react";
import {
    Box,
    Typography,
} from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useTheme } from '@mui/system';
import { tokens } from "../../theme";
import DrawerLogs from './DrawerLogs';


// Sample logs data
const initialLogs = [
    { id: 1, time: "18/08/2024 10:15:30", name: "Block-PDF-FILE", alertType: "Login", action: "Success", activity: "File Download", user: "Jane Smith", application: "App 1" },
    { id: 2, time: "18/08/2024 10:16:45", name: "Block-PDF-FILE", alertType: "Login", action: "Failed", activity: "Create", user: "Jane Smith", application: "App 1" },
    { id: 3, time: "18/08/2024 10:17:00", name: "Block-PDF-FILE", alertType: "Login", action: "Success", activity: "File Download", user: "Jane Smith", application: "App 1" },
    { id: 4, time: "18/08/2024 10:17:15", name: "Block-PDF-FILE", alertType: "Login", action: "Failed", activity: "File Download", user: "Jane Smith", application: "App 1" },
    // Add more log entries as needed
];


function Logs() {
    const [logs] = useState(initialLogs);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleRowClick = (params) => {
        if (params.field === "time") {
            setDrawerOpen(true);
        }
    };

    // Define columns for the DataGrid
    const columns = [
        { field: "id", headerName: "#", width: 70 },
        {
            field: "time",
            // make the header name bold
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    TIME
                </Typography>
            ),
            flex: 1,
            renderCell: (params) => (
                <Typography
                    sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginTop: "15px",
                        fontSize: "12px",
                    }}
                    color={colors.greenAccent[400]}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "name",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    NAME
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },

        {
            field: "alertType",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    ALERT TYPE
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },
        {
            field: "action",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    ACTION
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },
        {
            field: "activity",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    ACTIVITY
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },
        {
            field: "user",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    USER
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },
        {
            field: "application",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    APPLICATION
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                </>
            ),
        },
    ];
    return (
        <Box className="m-5">
            {/* Header Component */}
            <Header title="Logs" subtitle="View logs and monitor activity" />


            {/* DataGrid for logs */}
            <div className="mt-1" sx={{ height: 'calc(100vh - 100px)', width: '100%' }}>
                <DataGrid
                    rows={logs}
                    columns={columns}
                    pageSize={8}
                    rowsPerPageOptions={[5, 10, 20]}
                    disableRowSelectionOnClick
                    disableColumnResize
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSorting
                    onCellClick={handleRowClick}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 8,
                            },
                        },
                    }}
                    style={{ height: `${window.innerHeight - 100}px` }}
                    sx={{
                        '&, [class^=MuiDataGrid]': {
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: 'none'
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none', // Removes the focus border when a cell is focused
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none', // Ensures there's no border when focus is within the cell
                        },
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none', // Removes the focus border from the header cells
                        },
                        '& .MuiDataGrid-columnHeader:focus-within': {
                            outline: 'none', // Removes the focus border within the header cells
                        },
                    }}
                />
            </div>
            <DrawerLogs
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            />
        </Box>
    );
};

export default Logs;
