import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
    Button,
    Modal,
    Menu,
    IconButton,
    MenuItem,
    Chip,
    Snackbar,
    Dialog,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import { CircularProgress } from '@mui/material/';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';
import Header from '../../components/Header';
import DrawerConfig from './DrawerConfig';
import PolicyForm from './PolicyForm'; // Assuming you have a form component
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import api from '../../api/api';
import { format } from 'date-fns';

function HttpTab() {
    const theme = useTheme();
    const [rows, setRows] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState(null); // Store the selected policy for configuration/edit
    const [menuAnchorEl, setMenuAnchorEl] = useState(null); // Separate menu control from the selected policy
    const [isEditMode, setIsEditMode] = useState(false); // Track whether we're in edit mode or add mode
    const [openSnackbar, setOpenSnackbar] = useState(false); // Track whether we're showing a snackbar
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false); // Track whether we're showing a confirmation dialog
    const [loading, setLoading] = useState(true);
    const [hasChanges, setHasChanges] = useState(false);
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPolicies = async () => {
            try {
                const response = await api.get('/api/policy?action=read', { withCredentials: true });
                const sortedPolicies = response.data.sort((a, b) => a.id - b.id);
                setRows(sortedPolicies);
            } catch (error) {
                console.error('Failed to fetch policies:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchPolicies();

    }, []);

    const filteredRows = useMemo(() => {
        return rows.filter((row) =>
            // Check if the search query matches PolicyName, PolicyID, Action, or ID
            row.PolicyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.PolicyID.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.Action.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.id.toString().includes(searchQuery) // Convert to string to handle numbers
        );
    }, [rows, searchQuery]);

    const handleOpen = () => {
        setSelectedPolicy(null); // Reset selected policy for a fresh add form
        setIsEditMode(false); // Make sure we're not in edit mode when adding a new policy
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleRowClick = (params) => {
        if (params.field === "PolicyName") {
            setSelectedPolicy(params.row);
            setDrawerOpen(true);
        }
    };

    const handleMenuOpen = (event, policy) => {
        setMenuAnchorEl(event.currentTarget);
        setSelectedPolicy(policy); // Set the policy associated with the row
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleConfigure = () => {
        navigate('/dashboard/policy/rule', { state: { policy: selectedPolicy } }); // Use the selected policy for configuration
        handleMenuClose();
    };

    const handleEdit = () => {
        setIsEditMode(true); // Set edit mode to true when editing
        setOpen(true); // Open the modal for editing
        handleMenuClose();
    };

    const handleDeleteRow = async () => {
        try {
            await api.delete(`/api/policy?action=delete&id=${selectedPolicy.id}`, { withCredentials: true });

            // Remove the deleted policy from the rows state
            setRows((prevRows) => prevRows.filter((row) => row.id !== selectedPolicy.id));

            // Show snackbar message
            handleSnackbar('Policy deleted successfully');

            // Close the delete confirmation dialog
            setDeleteConfirmation(false);
            setHasChanges(true);
        } catch (error) {
            console.error('Failed to delete policy', error);
        }
    };


    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmation(false);
    };

    const handleOpenDeleteConfirmation = () => {
        setDeleteConfirmation(true);
        handleMenuClose();
    };

    const handleAddPolicy = async (data) => {
        try {
            if (isEditMode) {
                // Update existing policy
                const response = await api.put(`/api/policy?action=update&id=${selectedPolicy.id}`, data, { withCredentials: true });
                setRows((prevRows) =>
                    prevRows.map((row) => (row.id === selectedPolicy.id ? response.data : row))
                );
                handleSnackbar('Policy updated successfully');
            } else {
                // Add new policy
                const response = await api.post('/api/policy?action=create', data, { withCredentials: true });
                setRows((prevRows) => [...prevRows, response.data]);
                handleSnackbar('Policy added successfully');
            }
            setHasChanges(true);
            handleClose();
        } catch (error) {
            console.error('Failed to save policy:', error);
        }
    };

    // function for handling published policy
    const handlePublish = async () => {
        try {
            // Add your publish logic here (e.g., send a request to the icap server)
            setHasChanges(false);
            console.log('Publishing policy...');
            handleSnackbar('Policy published successfully');
        } catch (error) {
            console.error('Failed to publish policy:', error);
        }
    };

    const handleToggleEnabled = (id) => {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === id
                    ? { ...row, enabled: row.enabled === "On" ? "Off" : "On" }
                    : row
            )
        );
    };

    const handleCopyPolicyId = (policyId) => {
        navigator.clipboard.writeText(policyId).then(() => handleSnackbar('Policy ID copied to clipboard')).catch(err => {
            console.error('Failed to copy policy ID: ', err)
        });
    };

    const getChipColor = (action) => {
        switch (action) {
            case 'BLOCK':
                return '#ffcccc'; // Light red
            case 'ALLOW':
                return '#ccffcc'; // Light green
            case 'WHITELIST':
                return '#cccccc'; // Grey
            case 'ALERT':
                return '#cccccc'; // Grey
            case 'User ALERT':
                return '#cccccc'; // Grey
            default:
                return '#eeeeee'; // Default grey   
        }
    };

    const columns = [
        { field: "id", headerName: "#", width: 70 },
        {
            field: "PolicyName",
            // make the header name bold
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    Policy name
                </Typography>
            ),
            flex: 1,
            renderCell: (params) => (
                <Typography
                    sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginTop: "15px",
                        fontSize: "12px",
                    }}
                    color={colors.greenAccent[400]}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "PolicyID",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    Policy ID
                </Typography>
            ),
            width: 200,
            renderCell: (params) => (
                <>
                    <span
                        className="font-bold"
                    >{params.value}</span>
                    <IconButton
                        aria-label="copy"
                        onClick={() => handleCopyPolicyId(params.value)}
                        size="small"
                        style={{ marginLeft: 'auto' }}
                    >
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                </>
            ),
        },
        {
            field: "Action",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    Action
                </Typography>
            ),
            flex: 1,
            renderCell: (params) => (
                <Chip
                    size="small"
                    variant="outlined"
                    label={params.value}
                    sx={{
                        backgroundColor: getChipColor(params.value),
                        color: params.value === 'BLOCK' ? 'red' : (params.value === 'ALLOW' ? 'green' : 'black'),
                        width: '100px', // Adjust width as needed
                        justifyContent: 'center',
                        fontWeight: 'bold',
                    }}
                />
            ),
        },
        {
            field: "enabled",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    Enabled
                </Typography>
            ),
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color={params.value === "On" ? "success" : "error"}
                    size="small"
                    onClick={() => handleToggleEnabled(params.row.id)}
                >
                    {params.value === "On" ? <ToggleOnOutlinedIcon /> : <ToggleOffOutlinedIcon />}
                </Button>
            ),
        },
        {
            field: "lastEdited",
            renderHeader: (params) => (
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    Last Edited
                </Typography>
            ),
            flex: 1,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ marginTop: "15px" }}>
                    {format(new Date(params.row.updatedAt), 'yyyy-MM-dd * HH:mm')}
                </Typography>
            ),
        },
        {
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, params.row)} // Pass the policy associated with this row
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleConfigure}> <span className="text-blue-600"><SettingsIcon /> Configure</span></MenuItem>
                        <MenuItem onClick={handleEdit}> <span className="text-green-600"><EditIcon /> Edit</span></MenuItem>
                        <MenuItem onClick={handleOpenDeleteConfirmation}> <span className="text-red-600"><DeleteIcon /> Delete</span></MenuItem>
                    </Menu>
                </Box>
            ),
        },
    ];

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleSnackbar = (message) => {
        setSnackbarMessage(message);
        setOpenSnackbar(true);
    };

    return (
        <div className="mx-8">
            <div className="flex items-center rounded-md justify-between">
                <div>
                    <Header subtitle="Create and manage HTTP policies." />
                    <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={handleOpen}
                    >
                        <AddIcon />
                        Add a Policy
                    </Button>
                    <Snackbar
                        className="text-center"
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        message={snackbarMessage}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    />
                </div>

                <div className="flex flex-col w-1/4" style={{ marginLeft: 'auto' }}>
                    {/* Search Bar */}
                    <div className="flex items-center bg-primary-400 rounded-md" style={{ backgroundColor: colors.primary[400] }}>
                        <InputBase
                            className="ml-2 flex-1"
                            placeholder="Search values"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <IconButton type="button">
                            <SearchIcon />
                        </IconButton>
                    </div>

                    {/* Publish Button under the Search Bar */}
                    <Box className="mt-3 flex justify-end">
                        <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            disabled={!hasChanges}
                            onClick={handlePublish}
                        >
                            <SendIcon />
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Publish'}
                        </Button>
                    </Box>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <PolicyForm onSubmit={handleAddPolicy} initialData={isEditMode ? selectedPolicy : null} />
                </Box>
            </Modal>

            <div className="mt-1" sx={{ height: 'calc(100vh - 100px)', width: '100%' }}>
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    pageSize={8}
                    rowsPerPageOptions={[5, 10, 20]}
                    disableRowSelectionOnClick
                    disableColumnResize
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSorting
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 8,
                            },
                        },
                    }}
                    onCellClick={handleRowClick}
                    style={{ height: `${window.innerHeight - 100}px` }}
                    sx={{
                        '&, [class^=MuiDataGrid]': {
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: 'none'
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none', // Removes the focus border when a cell is focused
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none', // Ensures there's no border when focus is within the cell
                        },
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none', // Removes the focus border from the header cells
                        },
                        '& .MuiDataGrid-columnHeader:focus-within': {
                            outline: 'none', // Removes the focus border within the header cells
                        },
                    }}
                />
            </div>
            <Dialog open={deleteConfirmation} onClose={handleCloseDeleteConfirmation} maxWidth="xs" fullWidth>
                <DialogTitle>
                    <strong className="text-lg font-bold">Are you sure?
                        <div className="float-right cursor-pointer">
                            <CloseIcon onClick={handleCloseDeleteConfirmation} />
                        </div>
                    </strong>
                </DialogTitle>

                <DialogContent className="p-4">
                    {/* Warning message */}
                    <div className="bg-red-100 text-red-600 p-2 rounded-md mb-4">
                        <h1>
                            Unexpected bad things will happen if you don't read this!
                        </h1>
                    </div>

                    {/* Descriptive message */}
                    <Typography variant="body2" className="mb-4">
                        This action <span className="font-semibold">CANNOT</span> be undone.
                        This will permanently delete the <span className="font-semibold underline">{selectedPolicy?.PolicyName}</span> policy and remove all associated data.
                    </Typography>
                </DialogContent>

                <Box className="p-4">
                    <Button
                        onClick={handleDeleteRow}
                        color="error"
                        variant="contained"
                        className="w-full h-8 bg-red-600 text-white text-lg font-semibold"
                    >
                        I understand, delete this policy
                    </Button>
                </Box>
            </Dialog>

            <DrawerConfig
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                selectedPolicy={selectedPolicy}
                onConfigChange={() => setHasChanges(true)}
            />
        </div>
    );
}

export default HttpTab;
