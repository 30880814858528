import React from 'react';
import { Drawer, IconButton, Typography, Divider, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const DrawerLogs = ({ open, onClose }) => {

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <div className="w-80 p-6">
                {/* Header */}
                <div className="flex justify-between items-center mb-4">
          <Typography variant="h6" className="font-bold">
            Log Details
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

                <Divider />

                {/* Log Detail Sections */}
                <Box className="my-4">
                    {/* Section for General Info */}
                    <Box className="mb-6">
                        <Typography variant="subtitle2" className="font-bold mb-1">
                            General
                        </Typography>
                        <div className="space-y-1">
                            <Box className="flex justify-between">
                                <Typography variant="body2">Incident ID:</Typography>
                                <Typography variant="body2">XXXXXX</Typography>
                            </Box>
                            <Box className="flex justify-between">
                                <Typography variant="body2">Access Method:</Typography>
                                <Typography variant="body2">XXXXXX</Typography>
                            </Box>
                            <Box className="flex justify-between">
                                <Typography variant="body2">Action:</Typography>
                                <Typography variant="body2">XXXXXX</Typography>
                            </Box>
                        </div>
                    </Box>

                    <Divider />

                    {/* Section for User Info */}
                    <Box className="mb-6 mt-4">
                        <Typography variant="subtitle2" className="font-bold mb-1">
                            User
                        </Typography>
                        <div className="space-y-1">
                            <Box className="flex justify-between">
                                <Typography variant="body2">User:</Typography>
                                <Typography variant="body2">XXXXXX</Typography>
                            </Box>
                            <Box className="flex justify-between">
                                <Typography variant="body2">Group:</Typography>
                                <Typography variant="body2">XXXXXX</Typography>
                            </Box>
                        </div>
                    </Box>

                    <Divider />

                    {/* Section for Application Info */}
                    <Box className="mb-6 mt-4">
                        <Typography variant="subtitle2" className="font-bold mb-1">
                            Application
                        </Typography>
                        <div className="space-y-1">
                            <Box className="flex justify-between">
                                <Typography variant="body2">Application:</Typography>
                                <Typography variant="body2">XXXXXX</Typography>
                            </Box>
                            <Box className="flex justify-between">
                                <Typography variant="body2">Path:</Typography>
                                <Typography variant="body2">XXXXXX</Typography>
                            </Box>
                        </div>
                    </Box>

                    <Divider />

                    {/* Section for DLP Info */}
                    <Box className="mb-6 mt-4">
                        <Typography variant="subtitle2" className="font-bold mb-1">
                            DLP
                        </Typography>
                        <div className="space-y-1">
                            <Box className="flex justify-between">
                                <Typography variant="body2">Policy:</Typography>
                                <Typography variant="body2">XXXXXX</Typography>
                            </Box>
                        </div>
                    </Box>
                </Box>
            </div>
        </Drawer>
    );
};

export default DrawerLogs;
