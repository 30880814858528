import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, InputLabel, Select, MenuItem, Chip, Autocomplete, IconButton,
  CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Box, TextField, Grid, Divider, FormControl, Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../api/api';

const availableValues = ['Financial Information', 'MIP Label', 'force.com', 'salesforce.com', 'example.com'];

const PolicyConfig = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const policy = location.state?.policy || null;

  const [formValues, setFormValues] = useState({
    policyName: policy?.PolicyName || '',
    description: policy?.Description || '',
  });
  const [boxes, setBoxes] = useState(policy?.conditions || [[{ selector: '', operator: '', value: [] }]]);
  const [isSaving, setIsSaving] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [existingExpressions, setExistingExpressions] = useState([]);

  useEffect(() => {
    const fetchExpressions = async () => {
      try {
        const response = await api.get(`/test/policyExpression?action=read&policyId=${policy.id}`, { withCredentials: true });

        const policyExpressions = response.data.filter(expr => expr.policyId === policy.id);

        if (policyExpressions.length > 0) {
          const expressionsConditions = policyExpressions.map(exp => exp.expression);
          setBoxes(expressionsConditions);
          setExistingExpressions(policyExpressions);
        } else {
          setBoxes([[{ selector: '', operator: '', value: [] }]]);
        }
      } catch (error) {
        console.error('Failed to fetch policy expressions:', error);
        setBoxes([[{ selector: '', operator: '', value: [] }]]);
      }
    };

    if (policy) {
      setFormValues({
        policyName: policy.PolicyName || '',
        description: policy.Description || '',
      });

      fetchExpressions();
    }
  }, [policy]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleConditionChange = useCallback((boxIndex, conditionIndex, field, value) => {
    setBoxes((prev) => {
      const newBoxes = [...prev];
      const updatedConditions = newBoxes[boxIndex].map((condition, index) => 
        index === conditionIndex ? { ...condition, [field]: value } : condition
      );
      newBoxes[boxIndex] = updatedConditions;
      return newBoxes;
    });
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!formValues.policyName.trim()) {
      errors.policyName = 'Policy name is required.';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const addCondition = useCallback((boxIndex) => {
    setBoxes((prev) => {
      const newBoxes = [...prev];
      newBoxes[boxIndex].push({ selector: '', operator: '', value: [] });
      return newBoxes;
    });
  }, []);

  const addBox = useCallback(() => {
    setBoxes((prev) => [...prev, [{ selector: '', operator: '', value: [] }]]);
  }, []);

  const removeCondition = useCallback((boxIndex, conditionIndex) => {
    setBoxes((prev) => {
      const newBoxes = [...prev];
      newBoxes[boxIndex] = newBoxes[boxIndex].filter((_, i) => i !== conditionIndex);

      if (newBoxes[boxIndex].length === 0) {
        newBoxes.splice(boxIndex, 1);
      }

      return newBoxes;
    });
  }, []);

  const handleSavePolicy = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    setIsSaving(true);

    try {
      const data = {
        policyName: formValues.policyName.trim(),
        description: formValues.description.trim() || '',
      };

      // Combine all conditions into a single expression
      const combinedExpression = boxes.reduce((acc, conditions) => {
        acc.push(...conditions);
        return acc;
      }, []);

      if (policy && policy.id) {
        // Update the main policy data first
        await api.put(`/api/policy?action=update&id=${policy.id}`, data, {
          withCredentials: true,
          headers: { 'Content-Type': 'application/json' },
        });

        // Create or update the policy expression
        const policyExpression = {
          policyId: policy.id,
          expression: combinedExpression,
        };

        const existingPolicyExpressions = await api.get(
          `/test/policyExpression?action=read&policyId=${policy.id}`,
          { withCredentials: true }
        );

        const existingExpressionIds = existingPolicyExpressions.data.map(expression => expression.id);

        if (existingPolicyExpressions.data.length > 0) {
          // Update the existing expression
          const expressionId = existingPolicyExpressions.data[0].id;
          await api.put(
            `/test/policyExpression?action=update&id=${expressionId}&policyId=${policy.id}`,
            policyExpression,
            {
              withCredentials: true,
              headers: { 'Content-Type': 'application/json' },
            }
          );
        } else {
          // Create a new expression
          await api.post(`/test/policyExpression?action=create&policyId=${policy.id}`, policyExpression, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' },
          });
        }

        // Remove any old expressions not used anymore
        for (let i = boxes.length; i < existingExpressionIds.length; i++) {
          await api.delete(`/test/policyExpression?action=delete&id=${existingExpressionIds[i]}&policyId=${policy.id}`, {
            withCredentials: true,
          });
        }
      } else {
        throw new Error('No policy data available to update');
      }

      navigate('/dashboard/policy');
    } catch (error) {
      console.error('Error saving policy:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setOpenConfirmModal(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmModal(false);
    navigate(`/dashboard/policy`);
  };

  const handleCancelClose = () => {
    setOpenConfirmModal(false);
  };

  if (!policy) {
    return (
      <Box sx={{ padding: '2rem', textAlign: 'center' }}>
        <Typography variant="h6">No policy found. Please return to the policy list.</Typography>
        <Button variant="contained" onClick={() => navigate(`/dashboard/policy`)}>
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: '1rem',
        borderRadius: '1rem',
        maxWidth: '100rem',
        marginTop: '1rem',
      }}
    >
      <Box className="mx-auto">
        <Header title={policy.PolicyName} />
      </Box>

      <form onSubmit={handleSavePolicy}>
        <Box className="mb-4 p-4 rounded-lg shadow-md">
          <Box className="mb-2">
            <h6 className="font-bold">STEP 1</h6>
            <p><strong className='text-xl'>Name your policy</strong></p>
          </Box>
          <br />
          <Box className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
            <TextField
              autoFocus
              type="text"
              id="policyName"
              name="policyName"
              placeholder="Policy name (required)"
              className="w-1/4 p-2 border rounded-md"
              value={formValues.policyName}
              onChange={handleInputChange}
              required
              error={!!errors.policyName}
              helperText={errors.policyName}
            />
            <TextField
              type="text"
              id="description"
              name="description"
              placeholder="Description"
              className="flex-1 p-2 border rounded-md"
              value={formValues.description}
              onChange={handleInputChange}
            />
          </Box>
        </Box>

        <Box mb={4} p={8} borderRadius="0.5rem" boxShadow={3}>
          <h6 className="font-bold">STEP 2</h6>
          <p><strong className='text-xl'>Build an expression</strong></p>
          <p className='text-lg'>Set your policy scope by adding conditions to your expression.</p>
          <br />
          <strong className='text-lg'>Traffic</strong>
          <Divider className="mb-3" />

          {boxes.map((conditions, boxIndex) => (
            <React.Fragment key={boxIndex}>
              {boxIndex > 0 && Array.isArray(conditions) && conditions.length > 0 && ( // Only show the "OR" if there are multiple conditions
                <Box alignItems="center" mt={2} mb={2} justifyContent="center">
                  <strong variant="body2">OR</strong>
                </Box>
              )}
              <Box mt={4} p={2} borderRadius="0.5rem" boxShadow={2} mb={4}>
                {Array.isArray(conditions) && conditions.map((condition, conditionIndex) => (
                  <Box key={conditionIndex} mb={2} position="relative">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3}>
                        <Box className="p-2 rounded-md pb-1">  
                          <FormControl margin="dense" fullWidth sx={{ borderColor: 'white' }}>
                            <InputLabel>Selector required</InputLabel>
                            <Select
                              name="selector"
                              value={condition.selector}
                              onChange={(e) => handleConditionChange(boxIndex, conditionIndex, 'selector', e.target.value)}
                              required
                              sx={{ marginTop: '0.2rem' }}
                            >
                              <MenuItem value="DLP Profile">DLP Profile</MenuItem>
                              <MenuItem value="Domain">Domain</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Box className="p-2 rounded-md">
                          <FormControl fullWidth margin="dense" sx={{ borderColor: 'white' }}>
                            <InputLabel sx={{ fontSize: '0.85rem' }}>Operator required</InputLabel>
                            <Select
                              name="operator"
                              value={condition.operator}
                              onChange={(e) => handleConditionChange(boxIndex, conditionIndex, 'operator', e.target.value)}
                              required
                              sx={{ marginTop: '0.2rem' }}
                            >
                              <MenuItem value="in">in</MenuItem>
                              <MenuItem value="out">out</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box className="p-4 rounded-md">
                          <Autocomplete
                            multiple
                            options={availableValues || []}
                            value={condition.value || []}
                            onChange={(event, newValue) => handleConditionChange(boxIndex, conditionIndex, 'value', newValue)}
                            freeSolo
                            sx={{ marginTop: '0.2rem' }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, idx) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ idx })} />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select values"
                                variant="outlined"
                                size="medium"
                                name="value"
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <IconButton
                      aria-label="delete"
                      size="small"
                      sx={{ position: 'absolute', top: '10px', right: '10px' }}
                      onClick={() => removeCondition(boxIndex, conditionIndex)}
                    >
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                    {conditionIndex < conditions.length - 1 && (
                      <Box display="flex" alignItems="center" mt={2} mb={2}>
                        <strong>AND</strong>
                      </Box>
                    )}
                  </Box>
                ))}
                <Box display="flex" alignItems="center" mt={2}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="mb-4"
                    size="small"
                    onClick={() => addCondition(boxIndex)}
                  >
                    <AddIcon /> And
                  </Button>
                </Box>
              </Box>
            </React.Fragment>
          ))}

          <Box display="flex" alignItems="center" mt={2}>
            <Button
              variant="outlined"
              color="secondary"
              className="mb-4"
              size="small"
              onClick={addBox}
            >
              <AddIcon /> Or
            </Button>
          </Box>
        </Box>

        <Box className="flex justify-end space-x-4">
          <Button
            variant="outlined"
            color="secondary"
            className="mb-4"
            size="small"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className="mb-4"
            size="small"
            disabled={isSaving}
          >
            {isSaving ? <CircularProgress size={24} color="inherit" /> : 'Save Policy'}
          </Button>
        </Box>
      </form>

      <Dialog open={openConfirmModal} onClose={handleCancelClose}>
        <DialogTitle>Cancel Configuration</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure to cancel? Any unsaved configurations will be lost.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} variant="contained">Yes</Button>
          <Button onClick={handleCancelClose} variant="outlined" autoFocus>No</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PolicyConfig;