import React, { useState, useEffect } from "react";
import { CircularProgress } from '@mui/material/';

const PolicyForm = ({ onSubmit, initialData }) => {
    const [formData, setFormData] = useState({
        policyName: "",
        action: "ALLOW",
        enabled: false,
        description: "N/A",
        configuration: {}, // Assuming you're storing some JSON configuration here, but you can use any data structure you want
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (initialData) {
            setFormData({
                policyName: initialData.PolicyName,
                action: initialData.Action,
                enabled: initialData.Enabled === 'On',
                description: initialData.Description,
                configuration: initialData.Configuration,
            });
        }
    }, [initialData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Set loading state when form is submitted
        try {
            await onSubmit({
                policyName: formData.policyName,
                action: formData.action,
                enabled: formData.enabled,
                description: formData.description,
                configuration: formData.configuration,
            });
        } finally {
            setIsLoading(false); // Reset loading state once submission is done
        }
    };

    const actionOptions = [
        { value: "ALLOW", label: "ALLOW" },
        { value: "BLOCK", label: "BLOCK" },
        { value: "ISOLATE", label: "ISOLATE" },
        { value: "WHITELIST", label: "WHITELIST" },
        {value: "ALERT", label: "ALERT"},
        {value: "USER ALERT", label: "USER ALERT"},
    ];

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-4">
                {initialData ? "Edit Policy" : "Add Your New Policy Here"}
            </h1>
            <form className="grid grid-cols-1 gap-4" onSubmit={handleFormSubmit}>
                <div className="col-span-1">
                    <label
                        htmlFor="policyName"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Policy Name
                    </label>
                    <input
                        type="text"
                        id="policyName"
                        name="policyName"
                        className="w-full rounded-md border border-gray-300 py-2 px-3 text-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        placeholder="e.g., Block all traffic"
                        value={formData.policyName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="col-span-1">
                    <label
                        htmlFor="action"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Action
                    </label>
                    <select
                        id="action"
                        name="action"
                        className="w-full rounded-md border border-gray-300 py-2 px-3 text-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        value={formData.action}
                        onChange={handleChange}
                    >
                        {actionOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <button
                    type="submit"
                    className="inline-flex justify-center items-center px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={isLoading} // Disable button while loading
                >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : "Save"} {/* Show spinner when loading */}
                </button>
            </form>
        </div>
    );
};

export default PolicyForm;
