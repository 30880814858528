import React, { useState } from 'react';
import { useTheme } from '@mui/system';
import { Autocomplete, TextField, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { tokens } from "../../theme"; // import your color tokens

const options = [
  'Audit Test',
  'DLP-demo',
  'Amir try',
  'Sites Allowlist',
  'Download Sites (Predefined)',
  'ChatGPT',
  'Social Media Sites (Predefined)'
];

function DLP() {
  const [value, setValue] = useState('');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // get the appropriate theme colors

  return (
    <div className="p-6">
      {/* Step 1 */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Step 1</h2>
        <h3 className="text-sm font-medium mb-2">Set Policy Details</h3>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 mt-4">
          {/* Name Field */}
          <TextField 
            label="Name" 
            variant="outlined" 
            fullWidth 
            size="small" 
            defaultValue="Prevent Data Exposure"
            InputLabelProps={{
              style: { color: colors.grey[100] }
            }}
            sx={{ 
              '.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: colors.grey[500], // Change the border color
                },
              },
            }}
          />
          
          {/* Description Field */}
          <TextField 
            label="Description" 
            variant="outlined" 
            fullWidth 
            size="small"
            defaultValue="DLP Policy"
            InputLabelProps={{
              style: { color: colors.grey[100] }
            }}
            sx={{ 
              '.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: colors.grey[500],
                },
              },
            }}
          />
          
          {/* Category Select */}
          <FormControl fullWidth size="small" sx={{ color: colors.grey[100] }}>
            <InputLabel style={{ color: colors.grey[100] }}>Category</InputLabel>
            <Select 
              defaultValue="Browser DLP" 
              label="Category"
              sx={{ color: colors.grey[100], '.MuiOutlinedInput-root': { '& fieldset': { borderColor: colors.grey[500] } } }}
            >
              <MenuItem value="Browser DLP">Browser DLP</MenuItem>
              <MenuItem value="Email DLP">Email DLP</MenuItem>
            </Select>
          </FormControl>
          
          {/* Alert Severity Select */}
          <FormControl fullWidth size="small" sx={{ color: colors.grey[100] }}>
            <InputLabel style={{ color: colors.grey[100] }}>Alert Severity</InputLabel>
            <Select 
              defaultValue="High" 
              label="Alert Severity"
              sx={{ color: colors.grey[100], '.MuiOutlinedInput-root': { '& fieldset': { borderColor: colors.grey[500] } } }}
            >
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      {/* Step 2 */}
      <div>
        <h2 className="text-xl font-semibold mb-4">Step 2</h2>
        <h3 className="text-sm font-medium mb-2" style={{ color: colors.grey[200] }}>Choose Event Type and Conditions</h3>
        
        <div className="mt-4">
          {/* Event Type Select */}
          <FormControl size="small" sx={{ color: colors.grey[100] }}>
            <InputLabel style={{ color: colors.grey[100] }}>On Event</InputLabel>
            <Select 
              defaultValue="Browsing" 
              label="On Event"
              sx={{ color: colors.grey[100], '.MuiOutlinedInput-root': { '& fieldset': { borderColor: colors.grey[500] } } }}
            >
              <MenuItem value="Browsing">Browsing</MenuItem>
              <MenuItem value="Uploading">Uploading</MenuItem>
              <MenuItem value="Downloading">Downloading</MenuItem>
            </Select>
          </FormControl>
          
          {/* Conditions */}
          <div className="mt-6 border border-gray-300 p-4 rounded-lg" >
            <h3 className="text-sm font-medium text-gray-600 mb-2">Conditions</h3>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
              <FormControl fullWidth size="small" sx={{ color: colors.grey[100] }}>
                <InputLabel style={{ color: colors.grey[100] }}>When the</InputLabel>
                <Select 
                  defaultValue="Website" 
                  label="When the"
                  sx={{ color: colors.grey[100], '.MuiOutlinedInput-root': { '& fieldset': { borderColor: colors.grey[500] } } }}
                >
                  <MenuItem value="Website">Website</MenuItem>
                  <MenuItem value="File Type">File Type</MenuItem>
                  <MenuItem value="User">User</MenuItem>
                </Select>
              </FormControl>
              
              {/* Domain is in dropdown */}
              <FormControl fullWidth size="small" sx={{ color: colors.grey[100] }}>
                <Select
                  sx={{ color: colors.grey[100], '.MuiOutlinedInput-root': { '& fieldset': { borderColor: colors.grey[500] } } }}
                >
                  <MenuItem value="domain is in">
                    Domain is in
                  </MenuItem>
                </Select>
              </FormControl>

              {/* Searchable Autocomplete */}
              <Autocomplete
                options={options}
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    label="Select..." 
                    variant="outlined" 
                    size="small"
                    InputLabelProps={{
                    }}
                    sx={{ 
                      '.MuiOutlinedInput-root': {
                        '& fieldset': {
                        },
                      },
                    }}
                  />
                )}
              />
              
              <div className="flex items-center">
                <Button 
                  variant="outlined" 
                  size="small" 
                  className="mr-2" 
                >
                  Or Statement
                </Button>
                <Button 
                  variant="outlined" 
                  size="small" 
                >
                  Condition +
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DLP;
