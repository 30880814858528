import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PolicyIcon from '@mui/icons-material/Policy';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DevicesIcon from '@mui/icons-material/Devices';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import HttpsIcon from '@mui/icons-material/Https';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import Divider from '@mui/material/Divider';


const Item = ({ title, to, icon, selected, setSelected, Iscollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Tooltip title={Iscollapsed ? title : ""} placement="right">
      <MenuItem
        active={selected === title}
        style={{ color: colors.grey[100] }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    </Tooltip>
  )
}



const Sidebar = ({ onCollapse }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [Iscollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const logo = theme.palette.mode === "light" ? "../../images/dashboard-white-mode.png" : "../../images/dashboard-dark-mode.jpg";

  const handleCollapse = () => {
    setIsCollapsed(!Iscollapsed);
    onCollapse(!Iscollapsed); // Notify parent of collapse state
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important"
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={Iscollapsed} style={{ height: "148vh" }}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={handleCollapse}
            icon={Iscollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              marginBottom: "30px",
              color: colors.grey[90],
              
            }}
          >
            {!Iscollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >

                <img src={logo} alt="Denovosecurity Logo" width="100px" height="100px" />  {/* Use imported image */}
                <IconButton onClick={handleCollapse}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>

            )}
          </MenuItem>
          {/* USER */}
          {/* {!Iscollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Admin
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  Test Admin
                </Typography>
              </Box>
            </Box>
          )} */}
          {/* Menu Items */}
          <Box paddingLeft={Iscollapsed ? undefined : "10%"}>
            {/* <Tooltip title="Dashboard" placement="right"> */}
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeIcon />}
              selected={selected}
              setSelected={setSelected}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Users"
              to="/dashboard/users"
              icon={<PeopleAltIcon />}
              selected={selected}
              setSelected={setSelected}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Policy"
              to="/dashboard/policy"
              icon={<PolicyIcon />}
              selected={selected}
              setSelected={setSelected}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="DLP"
              to="/dashboard/dlp"
              icon={<HttpsIcon />}
              selected={selected}
              setSelected={setSelected}
              Iscollapsed={Iscollapsed}
            />
            {/* </Tooltip> */}
            <Item
              title="Devices"
              to="/dashboard/devices"
              icon={<DevicesIcon />}
              selected={selected}
              setSelected={setSelected}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Data Exchange"
              to="/dashboard/data-exchange"
              icon={<DataUsageIcon />}
              selected={selected}
              setSelected={setSelected}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Logs"
              to="/dashboard/logs"
              icon={<ShowChartIcon />}
              selected={selected}
              setSelected={setSelected}
              Iscollapsed={Iscollapsed}
            />
            <Item
              title="Settings"
              to="/dashboard/settings"
              icon={<SettingsIcon />}
              selected={selected}
              setSelected={setSelected}
              Iscollapsed={Iscollapsed}
            />
          </Box>
          {/* Add the divider */}
          <Divider />
        </Menu>
      </ProSidebar>
    </Box>
  );
}

export default Sidebar;