import { Box, IconButton, useTheme, Menu, Avatar, CircularProgress, Typography, Divider, Button } from "@mui/material";
import React, { useState, useContext } from "react";
import { tokens, ColorModeContext } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from '@mui/material/Tooltip';
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import AuthContext from "../auth/AuthContext";

const Topbar = ({ isCollapsed }) => {  // Accept isCollapsed as a prop
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const { logout, user, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logout();
        navigate("/login");
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" p={2}>
                {/* Search Bar */}
                <div className="flex bg-primary-400 rounded-md w-1/4 h-10" style={{ backgroundColor: colors.primary[400], marginLeft: 'auto' }}>
                    <InputBase className="ml-2 flex-1" placeholder="Search" />
                    <IconButton type="button" className="p-1">
                        <SearchIcon />
                    </IconButton>
                </div>

                {/* ICONS */}
                <Box display="flex">
                    <IconButton onClick={colorMode.toggleColorMode}>
                        {theme.palette.mode === "dark" ? (
                            <DarkModeOutlinedIcon className="text-grey-500" />
                        ) : (
                            <LightModeOutlinedIcon className="text-yellow-500" />
                        )}
                    </IconButton>
                    <IconButton>
                        <NotificationsOutlinedIcon />
                    </IconButton>
                    <Box>
                        <Tooltip title={user?.username || "Guest"}>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                            >
                                <Avatar
                                    sx={{
                                        backgroundColor: user?.email ? colors.blueAccent[700] : colors.grey[100],
                                        color: user?.email ? colors.grey[100] : colors.blueAccent[500],
                                    }}
                                >
                                    {user?.email ? user.email.substring(0, 2).toUpperCase() : "U"}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                mt: 1.5,
                                minWidth: "240px",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                                overflow: "visible",
                            },
                        }}
                    >
                        <div className="p-4 rounded-lg">
                            <div className="flex items-center space-x-4 mb-4">
                                <Avatar
                                    className="w-10 h-10 rounded-full"
                                    sx={{
                                        backgroundColor: user?.email ? "#1E3A8A" : "#E5E7EB",
                                        color: user?.email ? "#fff" : "#1E3A8A",
                                    }}
                                >
                                    {user?.email?.substring(0, 2).toUpperCase() || "U"}
                                </Avatar>
                                <div>
                                    <Typography variant="h6" className="font-bold">
                                        {user?.username || "Guest"}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {user?.email || "No email"}
                                    </Typography>
                                </div>
                            </div>

                            <Divider />

                            <Divider className="my-2" />

                            <Button
                                variant="outlined"
                                color="error"
                                fullWidth
                                onClick={handleLogout}
                                startIcon={<LogoutIcon />}
                                sx={{ mt: 1 }}
                            >
                                {loading ? <CircularProgress size={24} /> : "Sign out"}
                            </Button>
                        </div>
                    </Menu>
                </Box>
            </Box>
            <Divider
                sx={{
                    "&.MuiDivider-root": {
                        height: "1px",
                        width: isCollapsed ? "calc(100% - 2px)" : "calc(100% - 57px)",  // Adjust width based on collapse state
                        margin: "auto",
                        marginTop: "-17px",
                    },
                }}
            />
        </>
    );
};

export default Topbar;
