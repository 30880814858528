import React, { useState, useContext } from "react";
import { Box, Link, CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import { useFormik } from 'formik';
import * as Yup from 'yup';

function Login() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [serverError, setServerError] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await login(values.email, values.password);
        navigate("/dashboard");
      } catch (error) {
        if (error.response && error.response.data.message) {
          setServerError(error.response.data.message);
        } else {
          setServerError("An unexpected error occurred. Please try again later.");
        }
        setSubmitting(false);
      }
    },
  });

  const logo = "../../images/dashboard-white-mode.png";

  return (
    <Box className="bg-gray-50 font-[sans-serif]">
      <Box className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
        <Box className="max-w-md w-full">
          <a href="#"><img src={logo} alt="logo" className='w-40 mb-8 mx-auto block' /></a>
          <Box className="p-8 rounded-2xl bg-white shadow">
            <h2 className="text-gray-800 text-center text-2xl font-bold">Sign in</h2>
            <form className="mt-8 space-y-4" onSubmit={formik.handleSubmit}>
              <Box>
                <label className="text-gray-800 text-sm mb-2 block">Email address</label>
                <Box className="relative flex items-center">
                  <input
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    type="text"
                    className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-blue-600"
                    placeholder="Enter email address"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </Box>
              </Box>

              <Box>
                <label className="text-gray-800 text-sm mb-2 block">Password</label>
                <Box className="relative flex items-center">
                  <input
                    name="password"
                    type="password"
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-blue-600"
                    placeholder="Enter password"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                  ) : null}
                </Box>
              </Box>

              {serverError && (
                <Box className="text-red-600 text-sm my-2">{serverError}</Box>
              )}

              <Box className="flex flex-wrap items-center justify-between gap-4">
                <Box className="text-sm">
                  <a href="#" className="text-blue-600 hover:underline font-semibold">
                    Forgot your password?
                  </a>
                </Box>
              </Box>

              <Box className="!mt-8">
                <button type="submit" className="w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? <CircularProgress size={24} /> : "Sign in"}
                </button>
              </Box>
              <p className="text-gray-800 text-sm !mt-8 text-center">
                Don't have an account?{" "}
                <Link href="/signup" className="text-blue-600 hover:underline ml-1 whitespace-nowrap font-semibold">
                  Register here
                </Link>
              </p>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
