import React from 'react';
import { Drawer, Button, Typography, Divider, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
 
const DrawerConfig = ({ open, onClose, selectedPolicy }) => {
    const navigate = useNavigate();

    if (!selectedPolicy) return null;

    const handleCopyPolicyId = () => {
        navigator.clipboard.writeText(selectedPolicy.PolicyID).then(() => {
            alert('Policy ID copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy policy ID: ', err);
        });
    };

    const openPolicyConfig = () => {
        navigate('/dashboard/policy/rule', { state: { policy: selectedPolicy } });
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <div className="w-80 p-6">
                <div className="flex justify-between items-center mb-4">
                    <span className="font-bold text-xl"><strong>{selectedPolicy.PolicyName}</strong></span>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        className="mb-4"
                        size="small"
                        onClick={openPolicyConfig}
                    >
                        Configure
                    </Button>
                </div>
                <br />
                <Divider className="mb-3" />
                <Typography variant="subtitle1" className="font-weight-bold mb-2 text-center"><strong>Basic Information</strong></Typography>
                <div className="mb-2">
                    <Typography variant="body2" className="text-gray-500"><strong>Policy ID</strong></Typography>
                    <div className="flex items-center">
                        <Typography variant="body1" className="mr-2">{selectedPolicy.PolicyID}</Typography>
                        <IconButton
                            aria-label="copy"
                            onClick={handleCopyPolicyId}
                            size="small"
                        >
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
                <Divider className="mb-3" />
                <div className="mb-2">
                    <Typography variant="body2" className="text-gray-500"><strong>Policy description</strong></Typography>
                    <Typography variant="body1">{selectedPolicy.Description}</Typography>
                </div>
                <Divider className="mb-3" />
                <div className="mb-2">
                    <Typography variant="body2" className="text-gray-500"><strong>Date created</strong></Typography>
                    <Typography variant="body1">{format(new Date(selectedPolicy.createdAt), 'yyyy-MM-dd HH:mm:ss')}</Typography>
                </div>
                <Divider className="mb-3" />
                <div className="mb-2">
                    <Typography variant="body2" className="text-gray-500"><strong>Expression</strong></Typography>
                    </div>
            </div>
        </Drawer>
    );
};

export default DrawerConfig;
